import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { ArrowRight20 } from "@carbon/icons-react"

const ProductListingPromoBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="screen"
          showHeadline="false"
        >
          <div className="relative mx-auto max-w-screen-xl">
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="object-cover w-full h-auto"
              aspectRatio="16by9"
            />
            <Link
              className="bottom-0 right-0 w-full lg:absolute lg:w-1/3 lg:h-1/3 btn btn-primary "
              link={block.link}
            >
              <div className="flex flex-col justify-between h-full">
                <div>
                  <span className="block text-sm">{block.kicker}</span>
                  <span className="block mb-4 text-xl font-bold line-clamp-4">
                    {block.text}
                  </span>
                </div>
                <div>
                  <ArrowRight20 />
                </div>
              </div>
            </Link>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default ProductListingPromoBlock
